/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}

.react-datepicker{
  z-index: 9999;
  position: fixed;
}

.check_box.form-group{
  display: flex;
  align-items: center;

  label{
    margin-bottom: 0;
  }
  input{
    margin-left: 2rem;
    width: 25px;
  }
}

.input-group-append,
.input-group-prepend {
  color: $input-placeholder-color;
  width: auto;
  border: none;
  .input-group-text {
    border-color: $border-color;
    padding: 0.94rem 0.5rem 0.94rem 1rem;
    color: $input-placeholder-color;
  }
  button {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
}

.custom-control {
  .custom-control-label {
    line-height: 1.6;
    margin-bottom: 0;
  }
}

.form-control {
  border: 1px solid $border-color;
  font-family: $type1;
  font-size: $input-font-size;
  font-weight: 400;
  padding: 8px 12px;
  &.reservationDate{
    padding: 8px 4px;
  }
}

select {
  &.form-control {
    padding: .4375rem .75rem;
    border: 0;
    outline: 1px solid $border-color;
    color: $input-placeholder-color;
    &:focus {
      outline: 1px solid $border-color;
    }
    @each $color,
    $value in $theme-colors {
      &.border-#{$color} {
        outline: 1px solid $value;
        &:focus {
          outline: 1px solid $value;
        }
      }
    }
  }
}

.form-group {
  label {
    font-size: $default-font-size;
    line-height: 1;
    vertical-align: top;
    margin-bottom: .5rem;
  }
  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }
  .file-upload-default {
    visibility: hidden;
    position: absolute;
  }
  .file-upload-info {
    background: transparent;
  }
}
.form-check-input {
  margin-left: 0;
}

.custom-file {
  .visibility-hidden {
  visibility: hidden;
  }
  .custom-file-label {
    background: $input-bg;;
    border: 1px solid $border-color;
    height: calc(2.25rem + 2px);
    font-weight: normal;
    font-size: 0.875rem;
    padding: 0.625rem 0.6875rem;
    border-radius: 2px;
    &:after {
      background-color: theme-color(primary);
      height: auto;
      @extend .btn-primary;
    }
  }
}

.city-links {
  display: flex;
  flex-direction: column;
  min-height: 130px;

  .city-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e6e6e6;

    &.down {
      transition: transform 100ms ease-in-out;
      transform: translateY(100%);
    }

    &.up {
      transition: transform 100ms ease-in-out;
      transform: translateY(-100%);
    }

    &:hover {
      background-color: #f5f5f5;
    }
    .link-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .link-label {
        font-size: 14px;
        font-weight: 500;
        color: #4d4d4d;
      }
      .link-url {
        font-size: 11px;
        color: #4d4d4d;
      }
    }

    .link-buttons {
      display: flex;

      .link-button-delete {
        margin-right: 8px;
        color: theme-color(danger);
        cursor: pointer;
      }

      .link-pos {
        display: flex;
        flex-direction: column;
        align-items: center;

        .pos-chevron {
          cursor: pointer;
          color: #4d4d4d;
          flex: 1;
        }
      }
    }
  }
}
