.Operators {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 1.125rem;
  }

  .create-operator {
    width: fit-content;
    margin-bottom: 15px;
  }
  .OperatorsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .OperatorCard {
      display: flex;
      flex-basis: 32.3333%;
      padding: 15px 15px 20px;
      flex-direction: column;
      background-color: #FFFFFF;
      box-shadow: 0px 4px 40px rgba(202, 202, 202, 0.25);;
      border-radius: 5px;
      >div:first-child {
        display: flex;
        align-items: center;
        .operator-photo {
          margin-right: 20px;
          width: 60px;
          max-width: 60px;
          min-width: 60px;
          height: 60px;
          max-height: 60px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: 3px solid #1d9afa;
          position: relative;

          .editIcon {
            display: inline-flex;
            justify-content: center;
            position: absolute;
            right: 33%;
            top: 33%;
            color: #1d9afa;
            size: 10px;
            .file-input {
              display: none;
            }
            .mdi-lead-pencil {
              cursor: pointer;
            }
          }
        }
        .active-checkbox {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-left: auto;
          margin-right: 10px;
          >label {
            margin-bottom: unset;
          }
          >input {
            margin-left: 10px;
            width: 19px;
            height: 19px;
          }
        }
      }

      >button {
        margin-top: auto;
        width: 65%;
        align-self: center;
      }

      .permissions-textarea {
        border-color: #1d9afa;
        border-radius: 5px;
        padding: 5px;
      }
    }

    .AddOperator {
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 23px;
      min-height: 400px;
      &:hover {
        background-color: #E6ECEE;
      }
      .mdi-plus-circle {
        font-size: 50px;
      }
    }

    .PermissionsSelect__value-container {
      max-height: 100px;
      overflow-y: scroll;
    }
    .PermissionsSelect__multi-value__remove {
      display: none;
    }
  }
}