.PromoCodes {
    height: 100%;
}

.AddPromoCodeForm {
    select, input {
        height: 36px;
        color: #495057;
    }

    input {
        text-transform: uppercase;
        &.defaultText{
            text-transform: none!important;
        }
    }
}
