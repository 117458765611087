.FiltersSection {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .select-input {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 15px;

    >label{
      margin-bottom: 5px;
    }

    .react-select-container {
      min-width: 160px;
    }

    .react-select__menu {
      z-index: 9999;
    }
  }

  .dates-input {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    >label {
      margin-bottom: 5px;
    }
    .daterange-picker {
      align-items: center;
      .range-seperator {
        margin: auto 1rem;
      }
    }
    .react-datepicker__input-container {
      >input {
        height: 2.3rem;
        width: 110px;
      }
    }
  }

  >button {
    width: fit-content;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 20px;
    >input {
      visibility: hidden;

      &:checked {
        +.slider {
          background-color: #2196F3;
        }
        +.slider:before {
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }
      &:focus {
        +.slider {
          box-shadow: 0 0 1px #2196F3;
        }
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 1px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }
}
