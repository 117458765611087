.spinner-wrapper {
  position: relative;
  height: 200px;
  width: 100%;
  justify-content: flex-start;
  display: flex;
  align-items: flex-start;

  &.spinner-wrapper-small {
    height: 1.5rem;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  width: 100%;

  &.spinner-inline {
    position: fixed;
    height: 200px;
    width: calc(100vw - 385px);

    @media (max-width: 991px) {
      position: unset;
      width: 100%;
    }
  }

  &.spinner-small {
    position: relative;
    width: 100%;
    height: 1.5rem;

    .donut {
      height: 1.5rem;
      width: 1.5rem;
      border-width: 0.2rem;
    }
  }

  .donut {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.3rem solid lighten(theme-color(primary),30%);
    border-top-color: lighten(theme-color(primary),10%);
    animation: 1.5s spin infinite linear;
  }
}

.sidebar-icon-only .spinner-container.spinner-inline {
  width: calc(100vw - 185px);
}

:host-context(.sidebar-icon-only) {
  .spinner-container {
    width: calc(100% - #{$sidebar-width-icon} - 4rem);
  }
}

:host-context(.sidebar-mini) {
  .spinner-container {
    width: calc(100% - #{$sidebar-width-mini} - 4rem);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
