.search-container-wrapper {
  display: flex;
  align-items: center;
  width: 35%;
  margin-bottom: unset;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: unset;
  flex: 1;

  input {
    height: unset;
    min-width: 250px;
    width: 100%;
  }
}

.DataCardTable {
  .DataCardTable_header {
    display: flex;
    justify-content: space-between;
    margin-top: -15px;
  }

  .DataCardTable_cards {
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &.fixedFirstColumn {
    .rdt_TableBody {
      padding-left: 120px;
    }

    .rdt_TableHeadRow {
      padding-left: 120px;
    }

    .rdt_TableCol:nth-child(2), .rdt_TableCell:nth-child(2) {
      position: absolute;
      left: 0;
      width: 120px;
      background-color: inherit;
      border-right: 1px solid rgba(0,0,0,.12);
    }

    .rdt_TableCell:nth-child(2) {
      transform: translate(var(--data-table-scroll-x, 0), calc(-1 * var(--data-table-scroll-y, 0)));
      height: 48px;
      z-index: 9;
      border-bottom: 1px solid rgba(0,0,0,.12);
    }

    .rdt_TableCol:nth-child(2) {
      transform: translateX(var(--data-table-scroll-x, 0));
      height: 56px;
      z-index: 10;
      border-bottom: 1px solid rgba(0,0,0,.12);
    }
  }

  .no-data-wrapper {
    width: 100%;
    height: 50px;
    display: flex;
    position: relative;
    align-items: center;

    > span {
      position: absolute;
      left: calc(355px + var(--data-table-scroll-x, 0px));
      transform: translateX(-50%);
    }
  }

  &.visits-table {
    .rdt_TableCol:nth-of-type(12), .rdt_TableCell:nth-of-type(12) {
      border-left: 1px solid rgba(0,0,0,.12);
    }
    .rdt_TableCol:nth-of-type(14), .rdt_TableCell:nth-of-type(14) {
      border-right: 1px solid rgba(0,0,0,.12);
    }
  }
}

.property-cell {
  display: flex;
  flex-direction: column;
  .extra-info{
    max-height: 35px;
    overflow-y: scroll;
    background-color: inherit;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.pagination-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  > .pagination-container {
    width: calc(100vw - 385px);
    display: flex;
    justify-content: center;
  }
}

body.sidebar-icon-only {
  .pagination-wrapper > .pagination-container {
    width: calc(100vw - 215px);
  }
  .DataCardTable .no-data-wrapper > span {
    left: calc(455px + var(--data-table-scroll-x, 0px));
  }
}

div[id$="pagination"][id^="cell-"]:not(#cell-1-pagination) {
  display: none;
}

div#cell-1-pagination {
  min-width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.actions-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  >i {
    height: 18px;
    padding: 2px;
    cursor: pointer;
  }

  .action-verify {
    color:  darken(theme-color(info), 15%);
  }

  .action-review {
    color:  darken(theme-color(warning), 15%);
  }

  .action-accept {
    color: darken(theme-color(success), 15%);
  }

  .action-reject {
    color: darken(theme-color(danger), 15%);
  }
  > span {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

}

.transferred-select {
  width: 82px;
}




