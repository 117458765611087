
.RichTextEditor {
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  .btnContainer {
    margin-top: 1rem;
    display: inline-flex;

    .text-success{
      margin: 0;
    }

    > .btn {
      display: flex;
      justify-content: center;
      margin-right: 1rem;
      max-width: 1rem !important;

      &.active{
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
      }

      img {
        margin-right: unset;
        max-width: 1rem;
      }
    }
  }

  .editorContainer {
    border: #DFE3E6 solid 1px;
    width: 100%;
    padding: 0.5rem;
    margin-top: .5rem;
    box-shadow: 0 4px 40px rgba(202, 202, 202, 0.25);
    border-radius: 15px;
    overflow-y: scroll;
    margin-bottom: 1rem;
    flex: 1;
    min-height: 300px;
    background-color: #FFFFFF;
  }
}




