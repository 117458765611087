html, body, #root {
  font-family: 'Inter', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *::-webkit-scrollbar-thumb {
    background-color: #1d9afa;
  }

  *::-webkit-scrollbar-thumb:vertical:hover, &::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: #57B4F2;
  }

  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}

.scrollable {
  height: 100%;
  overflow-y: auto;
}

.clickable {
  cursor: pointer;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  padding-left: env(safe-area-inset-left, 0px);
  padding-right: env(safe-area-inset-right, 0px);
}

.react-player__preview {
  border-radius: 20px;
}

@keyframes adjust-hue {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.table_actions {
  i {
    height: 18px;
    padding: 2px;
    cursor: pointer;

    &.mdi-pencil {
      color: #1d9afa;
    }

    &.mdi-trash-can {
      color: #ff0000;
    }
  }
}

.edit-form {
  .form-group {
    display: flex;
    flex-direction: column;

    label + small {
      font-size: 11px;
      color: #666;
      margin-top: -5px;
    }

    textarea {
      resize: vertical;
      min-height: 90px;
    }

    select.form-control {
      color: #4d4d4d;
    }
  }
}

.integration-editor {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d8d8d8;
}

.ReactGrid-Wrapper {
  overflow-x: auto;

  .reactgrid-content {
    .rg-cell {
      &.disabled-cell {
        justify-content: center;
        align-items: center;
        background-color: #eeeeee;
      }
      &.rg-checkbox-cell label {
        border: 1px solid #cfcfcf;
      }
      &.fixed-width {
        font-family: 'Consolas', monospace;
      }
      &.clickable-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 15px;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .spinner-wrapper {
    position: absolute;
    z-index: 99;
    height: 100%;
  }

  &.loading {
    .reactgrid {
      opacity: 0.5;
    }
  }
}

.EditListings-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: -20px;
}

