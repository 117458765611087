.TenantCard {
  padding: 10px;
  display: flex;
  flex-direction: column;

  .TenantCard-top{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 3px;


    .tenant-photo{
      margin-right: 20px;
      width: 40px;
      max-width: 40px;
      min-width: 40px;
      height: 40px;
      max-height: 40px;
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      border: 3px solid white;
      position: relative;

      .RoundedCircleFlag {
        align-items: center;
        background-color: white;
        border-radius: 50%;
        display: inline-flex;
        height: 35%;
        justify-content: center;
        position: absolute;
        right: 35%;
        width: 35%;
        top: 80%;


        > div {
          background-position: center;
          background-size: cover;
          width: 80%;
          height: 80%;
          border-radius: 50%;
        }
      }
    }

    .tenant-info{
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 105px;

      >div{
        display: flex;

        .cityName{
          font-size: 12px;
          color: darkorange;
        }
      }

      >div:first-child {
        justify-content: space-between;
      }

      >div:not(:first-child) {
        font-size: 10px;
        color: #5A6872;
      }

      .contacts{
        flex-wrap: wrap;
      }

      .lookingFor-section{
        flex-direction: column;
      }

      .ratingStars{
        display: flex;
        >i {
          font-size: 9px;
        }
      }

      .yellow {
        color: yellow;
      }

      .grey {
        color: #c9c9c9;
      }
    }
  }

  .TenantCard-preferences{
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;
    min-height: 85px;

    >label{
      font-size: 11px;
      font-weight: 500;
    }
    >div {
      display: flex;
      font-size: 10px;
      color: #5A6872;
    }

    .lookingFor-section{
      flex-direction: column;
    }
  }

  .TenantCard-stats{
    width: 100%;
    height: fit-content;
    background-color: #F0F3F6;
    border: 1px solid #F0F3F6;
    border-radius: 6px;
    display: flex;
    padding: 5px;
    flex-direction: column;

    >div {
      display: flex;
    }
    >div:first-child{
      justify-content: space-between;
    }

    .stats-logo{
      display: flex;
      background-color: #FFFFFF;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      .reservations{
        color: #8862e0;
      }
      .visits{
        color: #ffaf00;
      }
      .offers{
        color: #3455ef;
      }
    }
    .section-title{
      font-size: 12px;
      color: #5A6872;
    }

    .section-subtitle{
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 10px;
      color: #5A6872;
    }

    .number {
      font-size: 12px;
      font-weight: 600;
    }
    .blue{
      color: deepskyblue;
    }
    .green{
      color: forestgreen;
    }
  }
  .TenantCard-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    >span {
      font-size: 7px;
    }
    >div {
      width: 70px;
    }
  }
}
