.onsite {
  background-color: #9575cd !important;
}

.video {
  background-color: #57b4f2 !important;
}

.info-dates {
  display: flex;
  flex-direction: column;

  .date-row {
    display: flex;
    justify-content: space-between;
  }

  > div {
    background-color: white;
    border-color: white;
    color: gray;
    padding: 10px;
  }
}

.action-button {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  border-radius: 7px;
  padding: 7px 15px 7px 15px;
  cursor: pointer;
  width: fit-content;
  > span {
    font-size: 14px;
  }
}

.action-schedule {
  background: #2b75f8;
  color: white;
}

.action-join {
  background: green;
  color: white;
  margin-top: 5px;
  padding: 5px 7px 5px 7px;
  >span {
    font-size: 12px;
  }
}

