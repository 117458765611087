.PropertyEdit_column{
  display: flex;
  flex-direction: column;
  max-width: 33%;
  margin-bottom: 10px;

  &.moreInfoColumn{
    max-width: 100%;
  }

  .PropertyEdit_column-label{
    font-weight: 700;
    font-size: .9rem;
    color: #18a0fb;
  }
  .PropertyEdit_column-label-seconday{
    font-weight: 500;
    font-size: .7rem;
    color: #18a0fb;
  }
  .PropertyEdit_input-label{
    font-weight: 600;
    margin-bottom: 10px;
    font-size: .813rem;
    color: #434a5f;
  }
  .PropertyEdit_row-inputs{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
  }
  .wrap{
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }
}

.PropertyEdit_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #DFE3E6;
  margin-bottom: 1rem;

  &.RoomEdit{
    flex-direction: column;
  }

  &.noBorder{
    border-bottom: none;
  }

  .Advanced__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    .Advanced__section-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      >span:first-child {
        font-size: 16px;
        font-weight: 700;
        color: #434A5F;
      }
      >span:last-child:not(:last-child) {
        font-size: 12px;
        font-weight: 400;
        color: #434A5F;
      }

    }

    .Advanced__section-content {
      margin-bottom: 15px;
      overflow: scroll;
      position: relative;
      max-height: calc(100vh - 480px);
      min-height: 115px;

      .Resident {
        display: flex;
        box-shadow: 0px 4px 40px rgba(202, 202, 202, 0.25);
        padding: 24px 22px 26px 24px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin-bottom: 25px;

        .Resident__photo {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          border:  2px solid #57B4F2;
          box-sizing: border-box;
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
        }

        .Resident__info {
          display: flex;
          flex-direction: column;
          .Resident__info-label {
            font-size: 13px;
            color: #404955;
            font-weight: 700;
            margin-bottom: 3px;
          }
          .Resident__info-value {
            font-size: 12px;
            color: #8C9BA5;
            font-weight: 400;
          }
        }
        .Resident__actions {
          display:flex;
          flex-direction: column;
          >a {
            margin-top: 5px;
            align-self: center;
          }
        }
        .Resident__button {
          cursor: pointer;
          &:hover {
            box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
          }

        }
      }

      .Resident__edit {
        flex-direction: column;
        .Loading {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .Resident__edit-dates {
          display: flex;
          width: 100%;
          .Resident__photo {
            cursor: pointer;
            position: relative;
            >input {
              display: none;
            }
            &:hover {
              &:after {
                position: absolute;
                content: "Edit";
                width: 61px;
                height: 61px;
                left: -1px;
                border-radius: 50%;
                font-size: 12px;
                background-color:rgba(0, 0, 0, 0.4);
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;

              }
            }
          }

          .DatePickerBackground {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
          }

          .Resident__edit-dates__inputs {
            display: flex;
            margin-left: 60px;
            border: 1px solid #DFE3E6;
            box-shadow: 0px 4px 40px rgba(202, 202, 202, 0.25);
            border-radius: 4px;
            padding: 8px 44px 11px 44px;
            align-items: center;
            width: 100%;
            justify-content: space-around;
            @media (max-width: 768px) {
              padding: 8px 10px 11px 10px;
            }
            @media (max-width: 580px) {
              flex-direction: column;

            }



            >div {
              display: flex;
              flex-direction: column;
              >label {
                font-size: 12px;
                color: #5A6872;
                font-weight: 400;
                margin-bottom: 3px;
              }
              .CustomDatePicker {
                .CustomInput {
                  display: flex;
                  font-weight: 700;
                  cursor: pointer;
                  min-width: 141px;
                  font-size: 14px !important;
                  >img {
                    margin-left: 25px;
                    position: unset;
                    height: 19px;
                  }
                }
              }
            }
          }
        }

        .Resident__edit-info {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 30px;

          .info {
            display: flex;
            flex-direction: column;

            .react-select-container {
              width: 189px;
            }

            >label{
              font-weight: 700;
              margin-bottom: 10px;
              font-size: 14px;
            }

            .occupation-options {
              display: flex;
              flex-wrap: wrap;
              >div {
                border: 1px solid #DFE3E6;
                border-radius: 5px;
                cursor: pointer;
                margin-right: 10px;
                padding: 10px 26px;
                font-size: 13px;
                white-space: nowrap;
                margin-bottom: 7px;
                &:hover:not(.selected) {
                  background-color: #DEE9EF;
                }
              }
              .selected {
                background-color: #1D9AFA;
                color: white;
              }
            }

            .input-select {

              .input-select__control {
                border-radius: 8px;
              }
              .input-select__control--is-focused {
                border: 1px solid #00D3FF;
                box-shadow: none;
              }

              .flag-control-wrapper {
                width: 200px;
                display: flex;
                align-items: center;
                padding-left: 5px;
                >img {
                  max-height: 16px;
                  max-width: 16px;
                }

              }
              .input-select__menu {
                .flag-option-wrapper {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  >img {
                    position: unset;
                    max-height: 11px;
                    max-width: 16px;
                  }
                }
              }
            }

          }
        }
        .info-personal {
          @media screen and (max-width: 840px) {
            flex-direction: column;
            .info {
              width: fit-content;
              margin-bottom: 10px;
            }
          }
        }
        .Resident__edit-buttons {
          display: flex;
          margin-left: auto;
          margin-top: 25px;
          >div:not(:last-child) {
            margin-right: 10px;
          }
          .btn_delete {
            background-color: #DD654B;
            color: white;
            cursor: pointer;
            &:hover {
              box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
            }
          }
        }

      }

    }
    .Advanced__add-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      width: fit-content;
      margin-left: auto;
      >svg {
        margin-left: 17px;
      }
      &:hover {
        >svg {
          scale: 1.2;
          animation: ease-in 0.2s;
        }
      }
    }

    .inactivate_container--btn{
      margin-top: 2rem;
      margin-left: 1rem;
      width: 135px;
    }
  }
}

.map_preview{
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-flex;
  justify-content:center;
  align-items:center;
}

.VisitWeekDays{
  display: flex;
  flex-direction: column;
  >div{
    margin-bottom: 5px;
  }
}


.VisitDay{
  display: flex;
  flex-direction: column;
  .VisitDay_button{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    >label{
      color: #434a5f;
      font-size: .875rem;
    }
  }
  .VisitDay_hours{
    display: flex;
    flex-direction: column;
    max-width: 167px;

    > div {
      display: flex;
      flex-direction: row;
      gap: 5px;
    }
    .close-button{
      border-radius: 7px;
      font-weight: 500;
      min-height: 36px;
      height: fit-content;
      border: none;
      padding: 0 10px;
      font-family: Poppins,sans-serif;
      display: flex;
      align-items: center;
      color: #ff6258;
    }
  }
}

.wrap-2-columns {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.input-size-75{
  max-width: 75px !important;
}

.Area-features{
  margin-bottom: 15px;
}

.Rent-months{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  >div:not(:last-child){
    margin-bottom: 3px;
  }
  .rent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

}
.Unavailability-container{
  display: inline-flex;
  align-items: flex-end;
  width: 100%;
  >div:not(:last-child){
    flex: 1;
    margin-right: 10px;
  }
  >div:last-child{
    flex: 0.5
  }
}
.datepicker-input{
  max-width: 76px;
}
.Select-motives{
  min-width: 160px;
  font-size: 11px;
}

.checkbox-label{
  color: #434a5f;
  font-size: .875rem;
}
.Rooms-title{
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #18a0fb;
}

.add-time-visit{
  display: flex;
  width: 23px;
  height: 20px;
  align-items: center;
  justify-content: center;
}
