/* Auth */

.auth {
	height: 100%;
	.login-half-bg {
		background: url("../../../images/backoffice_bg.jpg");
		background-size: cover;
	}
	.register-half-bg {
		background: url("../../../images/auth/register-bg.jpg");
		background-size: cover;
	}
	&.lock-full-bg {
		background: url("../../../images/auth/lockscreen-bg.jpg");
		background-size: cover;
	}
	.lock-profile-img {
		width: 90px;
		height: 90px;
		border-radius: 100%;
	}
	.auth-form-light {
		background: $white;
		select {
			color: $input-placeholder-color;
		}
		.input-group {
			.form-control {
				&:focus,
				&:active {
					border-color: $border-color;
				}
			}
		}
	}
	.auth-form-transparent {
		background: transparent;
		.form-control,
		.input-group-text {
			border-color: theme-color(secondary);
			padding: 0 15px;
			height: 40px;

			&:focus,
			&:active {
				border-color: theme-color(secondary);
			}

		}
		select {
			outline-color: theme-color(secondary);
		}
	}
	&.auth-img-bg {
		padding: 0;
		background-color: #1d3077;
		color: white;

		.auth-form-transparent {
			@media (min-width:768px) {
				width: 55%;
				margin: auto;
			}
		}
	}
	.brand-logo {
		margin-bottom: 2rem;
		img {
			width: 100px;
		}
	}
	form {
		.form-group {
			margin-bottom: 1.5rem;
			label {
				font-size: .8125rem;
			}
			.form-control {
				border-radius: 0;
				font-size: .9375rem;
			}
			.input-group {
				box-shadow: 0 0 17px 1px rgba(33, 153, 211, 0.35);
			}
		}
		.auth-form-btn {
			padding: 0.5rem 3rem;
			line-height: 1.5;
			position: relative;

			i {
				position: absolute;
				left: 15px;
				&:before {
					animation-duration: 1s;
				}
			}
		}
		.auth-link {
			font-size: $default-font-size;
			&:hover {
				color: initial;
			}
		}
	}
}
