.modal-dialog {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  justify-content: center;
  align-items: center;

  .modal-header .close {
    padding: 1rem 1rem;
    margin: -25px -20px -25px auto;
  }

  > .modal-content {
    width: 500px;

    > .modal-body {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  &.modal-lg, &.modal-xl {
    .modal-content {
      max-width: 1200px;
      width: 90%;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &.modal-sm > .modal-content {
    width: 300px;
  }
}

.modal-dialog >  {

}
