.info-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  > span {
    font-size: 13px;
  }

  .info-status-icon {

  }
}

.paid {
  color: #2ac12a;
}

.request, .pending {
  color: orange;
}

.expired {
  color: grey;
}

.rejected, .cancelled {
  color: red;
}

.reservation-info_box {
  padding: 3px 15px;
  border: 1px solid #9a9a9a;
  color: #424242;
  border-radius: 5px;
  display: inline;
}

.reservation-info-values {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 10px;

  > div {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }
}

.booking {
  background-color: #1D7CF2;
}

.instant {
  background-color: #9575CD;
}

.action-impersonate {
  background: #2b75f8;
  text-align: center;
  color: white;
  padding: 7px;
}

