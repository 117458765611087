.Card{
  display: flex;
  flex-direction: row;
  height: 140px;
  width: 100%;
  background-color: #f5f5f5;
  font-size: 12px;
  border-radius: 8px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 0 !important;
  }

  &:first-child {
    margin-top: 20px;
  }

  .Card_featured-photo {
    background-size: cover;
    background-position: center;
    max-width: 140px;
    min-width: 140px;
    height: 100%;
    margin-right: 20px;
    width: 120px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-repeat: no-repeat;
    padding: 10px;
    position: relative;

    &:after {
      content: '';
      background: linear-gradient(0deg, #2c2c2cbd 0%, #00000000 100%);
      position: absolute;
      width: 100%;
      height: 35px;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    > div:not(.bottom-image) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      border-radius: 12px;
      color: #fff;
      padding: 0 8px;
      font-size: 12px;
    }

    .bottom-image {
      position: absolute;
      bottom: 0;
      right: 10px;
      font-size: 11px;
      color: white;
      text-shadow: 2px 2px 4px #000000;
      z-index: 2;
    }

    .bottom-image-left {
      left: 10px;
      right: unset;
    }

    .top-left-image {
      position: absolute;
      top: 1px;
      left: 115px;
      height: 20px !important;
      width: 20px !important;
      border-radius: 50%;
    }
    .not_finished {
      background-color: yellow !important;
    }
    .finished {
      background-color: limegreen !important;
    }
    .disabled {
      background-color: red !important;
    }

    .top-image {
      height: 24px;
      border-radius: 12px;
      color: #fff;
      padding: 0 8px;
      font-size: 12px;
    }
  }
  .Card_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    min-width: 1000px;
    .Card_property {
      flex: 1;

      .Card_property--link{
        cursor: pointer;
      }

      .Card_property-landlord {
        display: flex;
        flex-direction: row;

        .landlord-photo {
          width: 20px;
          height: 20px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: 3px solid white;
        }
      }
    }
    > div:not(:last-child) {
      margin-right: 20px;
    }

    .Card_tenant {
      display: flex;
      flex: 1.2;

      .Card_tenant-image-wrapper {
        display: flex;
        align-items: center;
        margin-right: 10px;
        justify-content: center;

        .Card_tenant-image {
          width: 60px;
          height: 60px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
          border: 3px solid white;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .Card_info{
      flex: 1;
      .verified{
        color: #2DC928;
      }
    }
    .Card_actions {
      display: flex;
      flex-direction: column;
      flex:0.5;
      .info-box{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #dfe3e6;
        border-radius: 4px;
        padding: 10px 15px;
        background-color: white;
        width: 100%;
      }
      >span{
        cursor: pointer;
        color: #757474;
        text-decoration: underline;
        text-decoration-color: #757474;
        width: fit-content;
      }
    }

    .Card_extra {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
