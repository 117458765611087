
.RoomResume__info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 10px;
  flex: 1;
  >label{
    font-size: 1.125rem !important;
    font-weight: 700 !important;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  >p{
    color: #5a6872;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .813rem;
  }
  .RoomResume__info-titles{
    display: flex;
    flex-direction: column;
    >p {
      color: #5a6872;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: .813rem;
    }
    >p:not(:last-child){
      margin-bottom:unset;
    }
  }

  .verified{
    color: #2DC928;
  }
}
.RoomResume__price{
  >:first-child{
  font-size: 1.125rem;
  font-weight: 700;
  width: fit-content;
  }
  >p:not(:first-child):not(:last-child) {
    font-size: .75rem;
    line-height: 22px;
    color: #5a6872;
  }

  > p:last-child {
    font-size: .75rem;
    line-height: 22px;
    color: #18a0fb;
  }
}

.RoomResume__services {
  display: flex;
  flex-direction: column;

  > label {
    font-size: 0.8rem !important;
    font-weight: 700 !important;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}

.RoomResume__services-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 100px;

  > div {
    white-space: nowrap;
  }
}

.Room-Card{
  background-color: #ffffff !important;
  margin-bottom: 3px !important;
  margin-top: unset !important;
}


.action-edit {
  display: flex;
  flex-direction: row;
  background: #2b75f8;
  text-align: center;
  color: white;
  border-radius: 7px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  > span {
    font-size: 14px;
  }
}

.Rooms{
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    height: 0 !important;
  }
  >div:last-child{
    margin-bottom: 15px !important;
  }
}

.property-info {
  > label {
    font-weight: 700;
    color: #434a5f;
    font-size: .875rem;
  }
  >p{
    line-height: 22px;
    font-size: .875rem;
    color: #434a5f;
    margin-bottom: unset;
  }
}

.Room_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.RoomResume_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .buttons {
    display: flex;
    flex-direction: row;

    > div {
      display: flex;
      justify-content: center;
      align-content: center;
      min-width: 30px;
      min-height: 30px;
      color: white !important;
      border: 1px solid rgba(0, 0, 0, .1);
      width: 50%;
      opacity: 0.8;
    }

    > div:hover {
      opacity: 1.4;
    }
  }
}

.clicks-disabled {
  pointer-events: none;
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
  opacity: 50%;
}
.action-disabled{
  color: #666666;
  opacity: 50%;
  pointer-events: none;
}
.button-disabled{
  background-color: #666666;
  opacity: 50%;
  pointer-events: none;
}
.delete {
  background: red;
}

.delete-action{
  color:red;
}

.activate {
  background: #3cb371;
}
.activate-action{
  color: #3cb371;
}

.inactivate {
  background: #ffc107;
}
.inactivate-action{
  color: #ffc107;
  font-size: 12px;
}

.preview {
  background: #2b75f8;
}
.preview-action{
  color: #2b75f8;
}

.confirm {
  background: #8914d0;
}
.confirm-action{
  color: #8914d0;
}
.validate-action{
  color: rgb(29, 155, 240);
}
.validate{
  background-color: #03c2fc;
}
.link_blue {
  color: #18a0fb;
  font-size: .75rem;
  text-decoration: underline;
  line-height: 24px;
  white-space: nowrap;
}

.blank {
  color: #8c9ba5 !important;
  text-decoration: line-through;
}

.service {
  color: #284f83;
  font-weight: 400;
}

.property-actions{
  >div{
    max-height: 27px;
  }
}

.ExpandedRooms{
  display: flex;
  flex-direction: column;
  .labels{
    display: flex;
    flex-direction: row;
    >label{
      font-weight: 500;
      color: #434a5f;
      font-size: .875rem;
      flex:0.5;
    }
    >label:last-child{
      display: flex;
      flex: 0.2;
    }
  }
  >div:not(:last-child){
    margin-bottom: 3px;
  }
  >div:not(:first-child){
    border-bottom: 1px solid #DFE3E6;
  }
  >div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    >div{
      flex:0.5;
    }
    .Room-actions{
      display: flex;
      flex-direction: row;
      flex: 0.2;
      >i{
        cursor: pointer;
        margin-right: 7px;
      }
    }
  }
}

.available-date{
  color: #18a0fb;
}

.hidden{
  visibility: hidden;
}

.validated-icon{
  font-size: 24px;
  color: rgb(29, 155, 240);
  position: absolute;
  top: 0px;
  left: 10px;
}

.warning-icon{
  font-size: 19px;
  color: rgb(232, 81, 81);
  position: absolute;
  top: 5px;
  left: 10px;
}
.file-image{
  width: 80px;
  height: 80px;
  display: block;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 5px 1px #7272722b;
  border-radius: 3px;
  position: relative;

  > span {
    width: 100%;
    height: 100%;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.image-action {
  display: inline-flex;
  position: absolute;
  margin-top: 2px;
  font-size: 19px;

  i {
    text-shadow: 0 0 4px white;
  }
}

.delete-image {
  right: 0;
  top: -5px;
  font-size: 18px;
  color: rgb(232, 81, 81);
}

.highlight-image {
  right: 18px;
  top: -5px;
}

.move-right{
  right: 2px;
  bottom: 0;
  color: #2196f3;
}

.move-left{
  left: 2px;
  bottom: 0;
  color: #2196f3;
}


.display-photos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 385px;
  justify-content: unset !important;
}

.Submit-floating-button{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  height: 60px;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  box-shadow: 0 4px 40px rgba(0,0,0,0.4);
  >button{
    display: flex;
    align-items: center;
    margin-right: 15px;
    height: 35px;
  }
  >button:active{
    transform: scale(0.9);
    transition-duration: 150ms;
  }
  >button:hover{
    -moz-box-shadow: 0px 0px 4px 1px grey;
    -webkit-box-shadow: 0px 0px 4px 1px grey;
    box-shadow: 0px 0px 4px 1px  grey;
  }
}

.remove-card{
  animation: fadeOut ease 0.2s;
  -webkit-animation: fadeOut ease 0.2s;
  -moz-animation: fadeOut ease 0.2s;
  -o-animation: fadeOut ease 0.2s;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.map_header{
  display: flex;
  justify-content: space-between;
  margin-bottom: .7rem;
  label{
    margin-right: 1rem;
    strong{
      margin-right: 1rem;
    }
  }
}

.map_preview{
  display: inline-flex;
  justify-content:center;
  align-items:center;
  /*
   Blasting Circle Styles
  */
  @keyframes blast {
    0% {
      opacity: var(--loader-initial-scale, 0.5);
      transform: scale(var(--loader-initial-scale, 0.5));
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .loader.blasting-circle {
    width: var(--loader-width, 60px);
    height: var(--loader-height, 60px);
    border-radius: 80%;
    background-color: var(--loader-color-primary, #18A0FB);
    opacity:50%;
    animation: blast var(--animation-duration, 2s) infinite ease-out;
  }

}
.Map{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  .marker{
    width: 16px;
    height: 16px;
    z-index: 9;
    border-radius: 100px;
    background: linear-gradient(180deg, #1D9AFA 0%, #7080cd 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.9;
    transform: translate(-8px, -8px);
  }
}