.LandlordInformation_header {
  //for tenantPage
  .ratingStars{
    display: flex;
    >i {
      filter: drop-shadow(3px 0px 0px rgba(0, 0, 0,  0.4));
      font-size: 9px;
    }

    .yellow {
      color: #ffff00;
    }

    .grey {
      color: #c9c9c9;
    }
  }

  .avatar {
    display: flex;
    max-width: 160px;
    width: 160px;
    height: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: -80px auto 0;
    border-radius: 100%;
    border: 10px solid #fbfbfb;
    position: relative;

    >i {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 30px;
    }
  }

  .LandlordInformation_header-info {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .LandlordInformation_topstats{
    display: flex;
    justify-content: space-between;

    > div {
      flex: 1;
    }
  }
}

.LandlordInformation_body {
  .card-body {
    padding: 1rem;


  }
}

.LandlordInformation_tabs {
  display: block;
  text-align: justify;

  .DataCardTable .DataCardTable_header {
    margin-top: -50px;
    margin-bottom: 30px;
  }

  .mt-6 {
    margin-top: 4rem !important;
  }
}

.search-input {
  display: flex;
  align-items: center;
  background-color: white;
  height: 35px;
  width: 300px;
  >i {
    color: #2196f3;
    margin-right: 10px;
  }
  >input {
    border:unset;
    height: 35px;
  }
}

.ResultCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 15px 50px;
  background-color: #e5e9f1;
  border-radius: 4px;
  cursor: pointer;

  .ResultCard_property-info {
    display: flex;
    flex-direction: column;
    min-width: 321px;
    flex: 1;

    >span {
      font-size: 12px;
    }
    >div {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 5px;
      >span {
        font-size: 12px;
        font-weight: 400;
      }
    }
    >span:not(:last-child) {

    }
  }
  .ResultCard_property-rent {
    display: flex;
    flex-direction: column;
    min-width: 168px;
  }
  .ResultCard_property-extra {
    display: flex;
    flex-direction: column;
  }
}

.ResultRoomCard {
  display: flex;
  width: 100%;
  .ResultRoomCard-photo {
    height: 120px;
    width: 15%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .ResultRoomCard_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    height: 120px;
    padding: 10px 50px;
    background-color: #F5F7FA;
    border-radius: 4px;
    margin-bottom: 3px;

    > div {
      display: flex;
      flex-direction: column;

      >span {
        font-size: 13px;
        >strong {
          font-size: 14px;
        }
      }

      > input {
        height: 19px;
      }
    }
  }
}