.line-legend {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      margin-left: 10px;
      span {
        width: 20px;
        height: 3px;
        border-radius: 0px;
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
    }
    }
  }
}
.rounded-legend {
  ul {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    li {
      font-weight: 500;
      margin-right: 10px;
      span {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
        font-size: 0.875rem;
      }
    }
  }
}
.average-price-card {
  .card {
      background: $card-accent-color;
  }

  h3 {
      margin-bottom: 0;
  }

  .icon-holder {
      border-radius: 5px;
      border: 1px solid $iconholder-primary-border-color;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $iconholder-primary-bg;
      color: $iconholder-primary-color;
      font-size: 20px;

      i {
          line-height: 1;
      }
  }
}

#dashboard-vmap {
  height: 161px;
  width: 100%;

  .jvectormap-zoomin,
  .jvectormap-zoomout {
      display: none;
  }
}
.map-height {
  height: 160px;
}