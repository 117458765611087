.tenant_video {

  .video_presentation_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #317CF6;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    p {
      font-weight: 700;
      font-size: 16px;
      color: #57B4F2;
      margin-bottom: 0;
    }

    div {
      display: flex;
      align-items: center;

      label {
        margin-left: .5rem;
      }
    }
  }

  .react_player {
    margin-top: 15px;
    background: linear-gradient(180deg, rgba(109, 110, 114, 0.4) 0%, rgba(109, 110, 114, 0.1) 100%);
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.04);
    border-radius: 16px;

    video {
      background: linear-gradient(180deg, rgba(109, 110, 114, 0.4) 0%, rgba(109, 110, 114, 0.1) 100%);
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 20px;

    > input {
      visibility: hidden;

      &:checked {
        + .slider {
          background-color: #2196F3;
        }

        + .slider:before {
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
      }

      &:focus {
        + .slider {
          box-shadow: 0 0 1px #2196F3;
        }
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;

      &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 1px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }
}