.AmbassadorCard {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px !important;
  width: 320px;

  .AmbassadorCard__personal {
    display: flex;
    align-items: center;

    .photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .AmbassadorCard__stats {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .AmbassadorCard__stats__item {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .AmbassadorCard__withdrawals {
    margin-top: 10px;
    margin-bottom: 10px;

    .table {
      thead, tbody {
        display: block;
      }
      tbody {
        max-height: 100px;
        overflow-y: auto;
      }
    }
  }

  button {
    margin-top: auto;
  }

}